import APIService from './APIService';

export default {
  getImportacoes() {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get('importacoes/indger')
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getEntidadesIndger() {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get('importacoes/indger/get-entidades-indger')
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getExtensoesPermitidas() {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get('importacoes/indger/get-extencoes-permitidas')
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  save(data) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().post('importacoes/indger/salvar', JSON.stringify(data))
        .then(()  => resolve(true))
        .catch(() => reject(false));
    });
  },
  getLogsImportacao(id) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`importacoes/indger/logs/${id}`)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => reject(error));
    });
  },
  download(id) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`importacoes/indger/download/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },
  baixarLogs(id) {
    return APIService.apiCall().get(`importacoes/indger/logs-importacao/${id}`, {
      responseType: 'blob', ... {
        timeout: 60 * 60 * 1000
      }
    });
  },
  baixarLogsTecnicos(id) {
    return APIService.apiCall().get(`importacoes/indger/logs-tecnicos/${id}`, {
      responseType: 'blob', ... {
        timeout: 60 * 60 * 1000
      }
    });
  },
  // baixarArquivo(id) {
  //   return APIService.apiCall().get(`arquivos/download/${id}`, {
  //     responseType: 'blob', ... {
  //       timeout: 60 * 60 * 1000
  //     }
  //   });
  // },
  // baixarArquivoModelo(tipoImportacaoId) {
  //   return APIService.apiCall().get(`importacoes/indger/download/arquivo-modelo/${tipoImportacaoId}`, {
  //     responseType: 'blob', ... {
  //       timeout: 60 * 60 * 1000
  //     }
  //   });
  // },
}